/* PC Bifocal */

body.bifocal {
  .sub_menu {
    border-top: 20px #fff solid;
    background: transparent;
    ul {
      li:nth-child(5) {
        background: #3FA9F5;
        a {
          color: #fff;
        }
      }
    }
  }
  .inline-bl {
    text-align: left;
    font-size: 0;
    a {
      margin: auto 0;
      img {
        margin: 15px 10px 0;
      }
    }
    a:nth-child(-n+3) {
      img {
        margin-top: 0;
      }
    }
  }
  .s1 {
    margin-top: 65px;
    .txtarea {
      p {
        line-height: 2;
        img {
          float: right;
          padding-left: 20px;
          margin-bottom: 30px;
        }
      }
    }
  }
  .s2 {
    margin-top: 65px;
    img {
      float: right;
      margin-left: 25px;
    }
    .subtitle {
      font-size: $fs-18;
      color: #11B1AD;
      font-weight: bold;
    }
    .subtitle:before {
      content: "■";
    }
    p {
      line-height: 2;
    }
    .wrap {
      padding-top: 50px;
      text-align: center;
      .print-btn {
        width: 100%;
        background-color: #F7931E;
        padding: 10px 0;
        border-radius: 10px;
        text-align: center;
        font-size: 1.6em;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
      h4 {
        width: 100%;
        text-align: center;
        color: #11B1AD;
        font-size: 1.6em;
        border-top: solid 1px #11B1AD;
        border-bottom: solid 1px #11B1AD;
        padding: 10px 0;
        margin-bottom: 50px;
      }
      img {
        float: none;
        clear: both;
        margin-left: 0;
      }
      .notes {
        text-align: right;
        padding: 10px 0 30px;
      }
    }
  }
  .s3 {
    margin-top: 65px;
    .txtarea {
      p {
        line-height: 2;
        img {
          float: right;
          padding: 0 0 30px 20px;
        }
      }
    }
  }
  .s4 {
    margin-top: 65px;
    .txtarea {
      p {
        line-height: 2;
        img {
          float: right;
          padding: 0 0 30px 20px;
        }
      }
    }
  }
  .s5 {
    margin-top: 65px;
    padding-bottom: 65px;
    .wrap {
      margin-top: 50px;
      h4 {
        width: 400px;
        height: 0;
        text-align: center;
        font-size: 18px;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 40px solid #1c95d4;
        color: #fff;
        line-height: 40px;
        margin-left: 50px;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        td {
          width: 50%;
          box-sizing: border-box;
          padding: 20px;
          border: solid 2px #1c95d4;
          text-align: center;
        }
      }
      .btn {
        width: 100%;
        background-color: #ec75a1;
        padding: 10px 0;
        border-radius: 10px;
        text-align: center;
        font-size: 1.6em;
        margin-bottom: 50px;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .wrap:nth-of-type(2) {
      h4 {
        border-bottom: 40px solid #87bd43;
      }
      table {
        td {
          border: solid 2px #87bd43;
        }
      }
    }
    .wrap:nth-of-type(3) {
      h4 {
        border-bottom: 40px solid #ec75a1;
      }
      table {
        width: 100%;
        td {
          width: 50%;
          border: solid 2px #ec75a1;
        }
      }
    }
  }
}
