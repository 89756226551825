/* PC About */

body.about {
  .sub_menu {
    border-top: 20px #fff solid;
    background: transparent;
    ul {
      li:nth-child(1) {
        background: #3FA9F5;
        a {
          color: #fff;
        }
      }
    }
    .main {
      background: #fff;
    }
  }
  .inline {
    justify-content: space-around;
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .inline {
    justify-content: space-around;
  }
  .s1 {
    margin-top: 65px;
    p {
      line-height: 2.5;
    }
    .tel {
      text-align: center;
      img {
        display: inline-block;
      }
      p {
        font-size: $fs-20;
      }
    }
  }
  .s2 {
    .inner {
      overflow: hidden;
      .inner_cont_left {
        width: 470px;
        float: left;
        table {
          width: 100%;
          border-collapse: collapse;
          border: solid 1px $cl-grey;
          tr {
            border-bottom: solid 1px $cl-grey;
            td {
              border-right: solid 1px $cl-grey;
              text-align: center;
              padding: 15px;
              position: relative;
              .open_am,
              .open_pm {
                text-align: right;
                position: relative;
              }
              .open_am::before {
                position: absolute;
                content: url("/assets/img/calender_am.png");
                top: 0;
                left: 0;
              }
              .open_pm::before {
                position: absolute;
                content: url("/assets/img/calender_pm.png");
                top: 0;
                left: 0;
              }
            }
          }
        }
        .btn-store_print {
          padding: 24px 0;
        }
      }
      .inner_cont_right {
        width: 470px;
        display: inline-block;
        float: right;
        .open {
          margin-bottom: 50px;
          ul {
            position: relative;
            li {
              display: inline-block;
              margin: 0 30px 0 0;
              p {
                display: inline-block;
                margin-left: 60px;
              }
            }
          }
          .open_am::before {
            content: url("/assets/img/calender_am.png");
            position: absolute;
            top: 8%;
          }
          .open_pm::before {
            content: url("/assets/img/calender_pm.png");
            position: absolute;
            top: 8%;
          }
          .iframe {
            height: 280px;
            width: 100%;
            border: 1px solid #C3C1B2;
            background-color: #ffffff;
          }
        }
      }
    }
  }
  .s3 {
    .tel {
      width: 456px;
      margin: auto;
    }
    iframe {
      width: 100%;
    }
  }
  .h4 {
    .inner {
      .inner {
        .access_map {
          width: 100%;
          position: relative;
          padding-bottom: 56.25%;
          padding-top: 30px;
          height: 0;
          overflow: hidden;
          iframe,
          object,
          embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
