/* SP Topics */

body.topics,
body.post-type-archive-topics {
  .sub_menu {
    background: transparent;
    .main {
      background: #fff;
    }
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .s1 {
    margin-top: 30px;
    padding-bottom: 30px;
    .inner {
      dl {
        dt {
          color: #27B9CE;
          margin: 10px 0;
          border-bottom: 1px solid #27B9CE;
          font-size: $fs-16;
        }
        dd {
          margin-bottom: 30px;
        }
      }
    }
  }
}
