/*
 PC common
---------------------------------------- */

.sp-on {
  display: none;
}

.pc-on {
  display: block;
}

body {
  background-image: url('../img/top-bg.png');
  background-attachment: fixed;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: normal;
  color: $color;
  min-width: $min-width;
  .inner {
    max-width: $min-width;
    margin: 0 auto;
  }
  .inline {
    display: flex;
    flex-wrap: wrap;
  }
  .inline-bl {
    text-align: center;
    margin-bottom: 60px;
    * {
      display: inline-block;
      margin: 0px 10px;
    }
  }
  .clearfix {
    clear: both;
  }
  .title_hldr {
    background: #fff;
    text-align: center;
    padding: 4px 0;
    border-bottom: 6px solid $cl-blue;
    span {
      display: inline-block;
    }
  }
  .logo_hldr {
    background: #fff;
    padding: 20px 0;
    .l_hldr {
      float: left;
      width: 360px;
      padding: 10px 0;
      img {
        max-width: 100%;
      }
    }
    .c_hldr {
      width: 340px;
      float: right;
      line-height: 0;
      text-align: right;
      img {
        max-width: 100%;
        display: block;
        padding: 0;
      }
      p {
        display: inline-block;
        text-align: right;
        margin-top: 12px;
        line-height: 26px;
        a {
          border: 1px solid $color;
          padding: 1px 4px;
          margin: 0 4px;
          text-decoration: none;
          &.ac_contact {
            border: 0;
            position: relative;
            padding-left: 30px;
            margin-left: 10px;
            &:before {
              content: url('../img/ico-mail.png');
              display: inline-block;
              position: absolute;
              left: 0px;
              top: 1px;
            }
          }
        }
      }
    }
  }
  .main_menu {
    background: #fff;
    border-top: 2px solid #a4d1ec;
    border-bottom: 2px solid #a4d1ec;
    ul {
      padding: 10px 0px;
      text-align: center;
      display: table;
      width: 100%;
      li {
        width: 25%;
        display: table-cell;
        border-right: 1px solid $cl-darkgrey;
        vertical-align: middle;
        p {
          padding: 16px 0;
          vertical-align: middle;
          text-align: center;
          color: $cl-pink;
          font-size: $fs-16;
          text-decoration: none;
          font-weight: normal;
          line-height: 1.3em;
          font-feature-settings: "palt";
        }
        &:first-child {
          border-left: 1px solid $cl-darkgrey;
        }
      }
    }
  }
  .sub_menu {
    background: #fff;
    ul {
      text-align: center;
      padding: 20px 0px;
      font-size: 0;
      li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        background: $cl-lightgrey;
        height: 70px;
        position: relative;
        a {
          text-align: center;
          width: 156px;
          display: block;
          height: 100%;
          color: $color;
          font-size: $font-size;
          vertical-align: middle;
          text-decoration: none;
          font-weight: normal;
          line-height: 70px;
        }
        &:after {
          display: inline-block;
          vertical-align: middle;
          height: 70px;
          content: "";
        }
        &:hover {
          background: $cl-blue;
          a {
            color: #fff;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &:nth-child(3) a {
          line-height: 1.2em;
          padding: 19px 0;
        }
      }
    }
  }
  .banner {
    background: #fff;
    max-width: $max-width;
    margin: 0 auto;
  }
  .side_links {
    position: fixed;
    right: 10px;
    bottom: 10%;
    background: $cl-lightgrey;
    width: 180px;
    padding: 10px 10px 3px;
    border-radius: 5px;
    -webkit-box-shadow: 3px 4px 5px 0px rgba(196, 196, 196, 1);
    -moz-box-shadow: 3px 4px 5px 0px rgba(196, 196, 196, 1);
    box-shadow: 3px 4px 5px 0px rgba(196, 196, 196, 1);
    img {
      max-width: 100%;
    }
	.side_links_btn{
  	position: absolute;
    font-size: 30px;
	line-height: 125%;
	cursor: pointer;
    padding: 0px 15px;
	float: right;
	height: 40px;
    background-color: #9a9a9a;
    color: #fff;
    right: 10px;
    top: -40px;
	border-radius: 5px 5px 0 0;
    }
	.side_links_btn:hover{
		  color: #000;
		  transition: color 0.1s linear;
	}
}
  
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $cl-bluegreen;
    margin: 1em 0 40px 0;
    padding: 0;
  }
  .breadcrumbs {
    margin-bottom: 30px;
  }
  section {
    background: #fff;
    max-width: $max-width;
    margin: 0 auto;
    .cont {
      width: 673px;
      float: left;
    }
    h2 {
      font-size: $fs-25;
      color: $cl-pink;
      text-align: center;
      line-height: 1.2;
      margin-bottom: 30px;
    }
    h3.title {
      display: inline-block;
      width: 390px;
      background-image: url('../img/title-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      font-size: $fs-20;
      color: #fff;
      padding-left: 20px;
    }
    .to_top {
      display: inline-block;
      float: right;
    }
    .box {
      border: 2px solid $cl-lightgrey;
      padding: 15px 0 0;
      margin-bottom: 40px;
      h4.subtitle {
        float: right;
        margin-right: 20px;
        font-size: $fs-18;
      }
      ul {
        overflow-y: scroll;
        height: 320px;
        padding: 20px;
        margin-top: 20px;
        li {
          position: relative;
          a {
            text-decoration: none;
            word-break: break-all;
          }
          &:before {
            position: absolute;
            content: "●";
            margin-right: 5px;
          }
          p {
            padding-left: 1.4em;
          }
        }
      }
    }
    .sidebar {
      width: 280px;
      float: right;
      a {
        display: block;
        margin-bottom: 40px;
      }
    }
  }
  footer {
    .links {
      max-width: $max-width;
      margin: 0 auto;
      padding: 60px 0 30px;
      background: $cl-light-bluegreen;
      .col {
        width: 220px;
        margin-right: 36px;
        ul {
          margin-bottom: 30px;
          li {
            margin-left: 0px;
            font-size: $fs-12;
            padding-left: 0px;
            &:before {
              content: "・";
              display: inline-block;
            }
            a {
              text-decoration: none;
            }
            &:first-child {
              margin-bottom: 10px;
              margin-left: 0px;
              list-style: none;
              border-bottom: 2px solid $cl-darkblue;
              a {
                color: $cl-darkblue;
              }
            }
            &:first-child:before {
              content: "";
            }
          }
        }
        &:nth-child(4),
        &:nth-child(8) {
          margin-right: 0px;
        }
      }
    }
    .hldr {
      background: #fff;
      padding-top: 20px;
    }
    .copyright {
      font-size: $fs-12;
      padding: 4px 0;
      text-align: center;
      background: $cl-blue;
      color: #fff;
    }
  }
}
