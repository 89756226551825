/* PC Recommend */

body.recommend {
  .sub_menu {
    border-top: 20px #fff solid;
    background: transparent;
    ul {
      li:nth-child(3) {
        background: #3FA9F5;
        a {
          color: #fff;
        }
      }
    }
    .main {
      background: #fff;
    }
  }
  .inline {
    justify-content: space-around;
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .s1 {
    margin-top: 65px;
    p {
      line-height: 2.5;
    }
    .inner {
      overflow: hidden;
      .melsplan_logo {
        width: 853px;
        margin: auto;
        text-align: center;
      }
    }
  }
  .s2 {
    .inner {
      ul {
        margin-top: 45px;
        font-size: 0;
        li {
          display: inline-block;
          margin-right: 35px;
          margin-bottom: 30px;
        }
        li:nth-child(even) {
          margin-right: -13px;
        }
      }
    }
  }
  .s3 {
    margin-top: 65px;
    padding-bottom: 65px;
    .inner {
      ul {
        margin: 45px 0;
        font-size: 0;
        li {
          display: inline-block;
          margin-right: 62px;
        }
        li:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }
  .s4 {
    margin-top: 65px;
    padding-bottom: 65px;
    .inner {
      .tit_type {
        display: block;
        padding: 5px;
        width: 100%;
        text-align: center;
        color: #fff;
        background-color: #f1ab3e;
        font-size: 18px;
        margin-bottom: 30px;
      }
      .inline {
        justify-content: space-around;
      }
      .wrap {
        margin-top: 50px;
        h4 {
          width: 366px;
          height: 0;
          text-align: center;
          font-size: 18px;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 40px solid #1c95d4;
          color: #fff;
          line-height: 40px;
          margin-left: 50px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          text-align: center;
          td {
            width: 50%;
            box-sizing: border-box;
            padding: 20px 0;
            border: solid 2px #1c95d4;
            text-align: center;
          }
        }
      }
      .wrap:nth-of-type(3) {
        h4 {
          border-bottom: 40px solid #87bd43;
        }
        table {
          td {
            border: solid 2px #87bd43;
          }
        }
      }
      .wrap:nth-of-type(4) {
        width: 477px;
        display: inline-block;
        margin-right: 52px;
        h4 {
          border-bottom: 40px solid #8a2586;
        }
        table {
          td {
            width: 100%;
            border: solid 2px #8a2586;
          }
        }
      }
      .wrap:nth-of-type(5) {
        width: 466px;
        display: inline-block;
        h4 {
          border-bottom: 40px solid #0f6bb0;
        }
        table {
          td {
            width: 100%;
            border: solid 2px #0f6bb0;
          }
        }
      }
      .wrap:nth-of-type(6) {
        h4 {
          border-bottom: 40px solid #ed8f25;
        }
        table {
          td {
            border: solid 2px #ed8f25;
          }
        }
      }
      .wrap:nth-of-type(7) {
        h4 {
          border-bottom: 40px solid #1c8d44;
        }
        table {
          td {
            border: solid 2px #1c8d44;
          }
        }
      }
    }
    .btn_detail {
      margin-top: 60px;
      a {
        color: #fff;
        font-size: 1.6em;
        display: block;
        background-color: #ec75a2;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
  .s5 {
    margin-top: 65px;
    padding-bottom: 65px;
    .inner {
      .tit_type {
        display: block;
        padding: 5px;
        width: 100%;
        text-align: center;
        color: #fff;
        background-color: #2b97cc;
        font-size: 18px;
        margin-bottom: 30px;
      }
      .inline {
        justify-content: space-around;
      }
      .wrap {
        margin-top: 50px;
        img {
          max-width: 100%;
        }
        h4 {
          width: 366px;
          height: 0;
          text-align: center;
          font-size: 18px;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 40px solid #d66892;
          color: #fff;
          line-height: 40px;
          margin-left: 50px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          text-align: center;
          td {
            width: 50%;
            box-sizing: border-box;
            padding: 20px;
            border: solid 2px #d66892;
            text-align: center;
          }
        }
        .btn {
          width: 100%;
          background-color: #ec75a1;
          padding: 10px 0;
          border-radius: 10px;
          text-align: center;
          font-size: 24px;
          margin-bottom: 50px;
          a {
            color: #fff;
            text-decoration: none;
          }
        }
      }
      .wrap:nth-of-type(2) {
        h4 {
          border-bottom: 40px solid #56b8e1;
        }
        table {
          td {
            border: solid 2px #56b8e1;
          }
        }
      }
      .wrap:nth-of-type(3) {
        display: inline-block;
        margin-right: 52px;
        width: 477px;
        h4 {
          border-bottom: 40px solid #ed8f25;
        }
        table {
          td {
            width: 100%;
            border: solid 2px #ed8f25;
          }
        }
      }
      .wrap:nth-of-type(4) {
        display: inline-block;
        width: 466px;
        h4 {
          border-bottom: 40px solid #1c8d44;
        }
        table {
          td {
            width: 100%;
            border: solid 2px #1c8d44;
          }
        }
      }
    }
    .btn_detail {
      margin-top: 60px;
      a {
        color: #fff;
        font-size: 1.6em;
        display: block;
        background-color: #ec75a2;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
  .s6 {
    margin-top: 65px;
    padding-bottom: 65px;
    .inner {
      .inner_cont_01 {
        .careplus_logo {
          width: 915px;
          margin: 60px auto 30px;
        }
        ul {
          font-size: 0;
          margin: 30px 0;
          li {
            display: inline-block;
            margin-right: 80px;
          }
          li:nth-child(2) {
            margin-right: 0;
          }
        }
      }
      .inner_cont_02 {
        .border_txt {
          margin: 45px 0 30px;
          text-align: center;
          padding: 15px;
          border: 2px solid #FF7BAC;
          color: #FF7BAC;
          border-radius: 10px;
          font-size: $fs-25;
        }
        .inner_txt {
          margin: 20px 0;
        }
      }
      .inner_cont_03 {
        margin-top: 45px;
        .inner_txt {
          margin: 30px 0;
          color: #656766;
          font-size: $fs-25;
          text-align: center;
        }
        .melsplan_logo {
          width: 678px;
          margin: 0 auto 55px;
        }
      }
    }
  }
}
