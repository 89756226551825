// ----- fonts

$font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;

$font-size: 15px;
$font-size-sp: 13px;
$line-height: 1.8;

$fs-10: .71em;
$fs-11: .78em;
$fs-12: .85em;
$fs-13: .92em;
$fs-15: 1.07em;
$fs-16: 1.14em;
$fs-17: 1.21em;
$fs-18: 1.28em;
$fs-19: 1.35em;
$fs-20: 1.42em;
$fs-21: 1.5em;
$fs-22: 1.57em;
$fs-23: 1.64em;
$fs-24: 1.71em;
$fs-25: 1.78em;
$fs-28: 2em;
$fs-32: 2.28em;
$fs-48: 3.42em;

// ----- colors

$color: #333;
$cl-lightblue: #BBDCF4;
$cl-blue: #3FA9F5;
$cl-darkblue: #144a97;
$cl-grey: #bfbfbf;
$cl-darkgrey: #B3B3B3;
$cl-lightgrey: #F2F2F2;
$cl-bluegreen: #27B9CE;
$cl-light-bluegreen: #C9EDF3;
$cl-pink: #FF7BAC;
$cl-green: #39B54A;
$cl-purple: #313992;

// ----- layout

$min-width: 1000px;
$max-width: 1169px;