/* PC Topics */

body.topics,
body.post-type-archive-topics {
  .sub_menu {
    border-top: 20px #fff solid;
    background: transparent;
    ul {
      li:nth-child(6) {
        background: #3FA9F5;
        a {
          color: #fff;
        }
      }
    }
    .main {
      background: #fff;
    }
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .s1 {
    margin-top: 65px;
    padding-bottom: 65px;
    .inner {
      dl {
        dt {
          color: #27B9CE;
          margin: 10px 0;
          border-bottom: 1px solid #27B9CE;
          font-size: $fs-20;
        }
        dd {
          margin-bottom: 60px;
        }
      }
    }
  }
}

/* PC Campaign */

body.campaign {
  .sub_menu {
    border-top: 20px #fff solid;
    background: transparent;
  }
  .contents {
    padding-bottom: 65px;
  }
}
