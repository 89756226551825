/* SP Index */

body.home {
  .contents {
    padding: 0px;
  }
  .mv {
    background: #fff;
    padding-bottom: 0px;
    .bxslider-wrap {
      visibility: hidden;
    }
    .bx-wrapper {
      padding: 22px 0px 42px 0px;
      background: $cl-lightblue;
      box-shadow: none;
      border: 0px;
      margin-bottom: 0px;
      .bx-pager {
        bottom: 10px;
        z-index: 999;
        &.bx-default-pager a {
          background: #fff;
        }
      }
    }
    .bx-wrapper .bx-pager.bx-default-pager a.active,
    .bx-wrapper .bx-pager.bx-default-pager a:focus,
    .bx-wrapper .bx-pager.bx-default-pager a:hover {
      background: $cl-blue;
    }
    .br-blue {
      border: 5px solid $cl-blue;
    }
    .br-orange {
      border: 5px solid #F1A44D;
    }
    .br-drk {
      border: 5px solid #2A4174;
    }
    .br-green {
      border: 5px solid #4CB157;
    }
    .br-pink {
      border: 5px solid $cl-pink;
    }
  }
  .access {
    background: #fff;
    padding: 20px 10px 0;
    .left {
      margin-bottom: 20px;
      img {
        max-width: 100%;
      }
      a {
        margin-top: 10px;
        margin-right: 10px;
        display: inline-block;
        &.nm {
          margin-right: 0px;
        }
      }
    }
    .right {
      font-size: $fs-12;
      p {
        margin-top: 30px;
      }
      .blue {
        color: $cl-bluegreen;
      }
      img {
        max-width: 80%;
        margin: 0 auto;
        display: block;
      }
      .btn {
        display: block;
        margin-bottom: 10px;
        img {
          max-width: 100%;
        }
      }
      table {
        td {
          vertical-align: top;
        }
      }
      .store {
        display: flex;
        flex-wrap: wrap;
        .hldr {
          width: 50%;
          img {
            max-width: 100%;
          }
        }
      }
      h4 {
        text-align: center;
        margin: 10px auto;
      }
    }
    .to_top {
      display: inline-block;
      float: right;
      max-width: 80px;
      margin: 10px 0px;
      img {
        max-width: 100%;
      }
    }
  }
}
