/* SP Firsttime */

body.firsttime {
  .sub_menu {
    background: transparent;
    .main {
      background: #fff;
    }
  }
  .inline {
    justify-content: space-around;
    text-align: center;
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .s1 {
    margin-top: 65px;
    p {
      line-height: 2.5;
    }
    .inner {
      overflow: hidden;
      p {
        width: 502px;
        float: left;
      }
      img {
        float: right;
      }
    }
  }
  .s2 {
    .inner {
      .inner_charm {
        ul {
          text-align: left;
          font-size: 0;
          margin-bottom: 15px;
          text-align: center;
          width: 100%;
          li {
            margin: 0 0 15px 0;
          }
        }
        .btn_detail {
          a {
            font-size: $fs-15;
            color: #fff;
            display: block;
            background-color: #ec75a2;
            padding: 15px;
            text-decoration: none;
            text-align: center;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .s3 {
    .inner {
      .inner_type {
        .inner_txt {
          margin-bottom: 10px;
        }
      }
      .btn_detail {
        a {
          font-size: $fs-15;
          color: #fff;
          display: block;
          background-color: #ec75a2;
          padding: 7px;
          text-decoration: none;
          text-align: center;
          border-radius: 10px;
        }
      }
    }
  }
  .s4 {
    .inner {
      ul {
        text-align: left;
        font-size: 0;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
        li {
          display: inline-block;
          margin: 0 0 15px 0;
        }
      }
      .btn_detail {
        a {
          font-size: $fs-15;
          color: #fff;
          display: block;
          background-color: #ec75a2;
          padding: 7px;
          text-decoration: none;
          text-align: center;
          border-radius: 10px;
        }
      }
    }
  }
  .s5 {
    .inner {
      overflow: hidden;
      img {
        margin-bottom: 10px;
      }
    }
  }
}
