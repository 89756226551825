/* SP Contact */

body.trial{
	& .sub_menu{
		background: transparent;
		& .main{
			background: #fff;
		}
	}
	& .to_top{
		margin: 30px 0px 45px 0px;
	}
	& .sub_txt{
		text-align: center;
		font-size: 0.9em;
	}
	& .s1{
		margin-top: 30px;
		& .recommend{
			& ul{
				margin-top: -36px;
				& li{
					margin-right: -10px;
				}
				& li:nth-child(3){
					margin-top: -9px;
				}
			}
		}
		& p{
			line-height: 2.5em;
		}
	}
	& .btn_detail{
			a{
				font-size: $fs-15;
				color:#fff;
				display: block;
				background-color: #ed8f25;
				padding: 7px;
				text-decoration: none;
				text-align: center;
				border-radius: 10px;
				margin-bottom: 10px;
			}
		}
}