/* SP Handling */

body.handling {
  .sub_menu {
    background: transparent;
    .main {
      background: #fff;
    }
  }
  .inline {
    justify-content: space-around;
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .s1 {
    margin-top: 30px;
    padding-bottom: 30px;
    .inner {
      .tit_type {
        display: block;
        padding: 5px;
        width: 100%;
        text-align: center;
        color: #fff;
        background-color: #f1ab3e;
        font-size: 16px;
        margin-bottom: 30px;
      }
      .inline {
        justify-content: space-around;
      }
      .wrap {
        margin-top: 20px;
        h4 {
          width: 80%;
          height: 0;
          text-align: center;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 40px solid #1c95d4;
          color: #fff;
          line-height: 40px;
          margin-left: 20px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          text-align: center;
          border-bottom: solid 2px #1c95d4;
          td {
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            border: solid 2px #1c95d4;
            text-align: center;
            display: block;
            border-bottom: none;
          }
        }
      }
      .wrap:nth-of-type(2) {
        td:last-child {
          display: none;
        }
      }
      .wrap:nth-of-type(5) {
        h4 {
          border-bottom: 40px solid #87bd43;
        }
        table {
          border-bottom: solid 2px #87bd43;
          td {
            border: solid 2px #87bd43;
            border-bottom: none;
          }
        }
      }
      .wrap:nth-of-type(8) {
        width: 100%;
        display: inline-block;
        h4 {
          border-bottom: 40px solid #8a2586;
        }
        table {
          border-bottom: solid 2px #8a2586;
          td {
            width: 100%;
            border: solid 2px #8a2586;
            border-bottom: none;
          }
        }
      }
      .wrap:nth-of-type(11) {
        width: 100%;
        display: inline-block;
        h4 {
          border-bottom: 40px solid #0f6bb0;
        }
        table {
          border-bottom: solid 2px #0f6bb0;
          ;
          td {
            width: 100%;
            border: solid 2px #0f6bb0;
            border-bottom: none;
          }
        }
      }
      .wrap:nth-of-type(14) {
        h4 {
          border-bottom: 40px solid #ed8f25;
        }
        table {
          border-bottom: solid 2px #ed8f25;
          td {
            border: solid 2px #ed8f25;
            border-bottom: none;
          }
        }
      }
      .wrap:nth-of-type(17) {
        h4 {
          border-bottom: 40px solid #1c8d44;
        }
        table {
          border-bottom: solid 2px #1c8d44;
          td {
            border: solid 2px #1c8d44;
            border-bottom: none;
          }
          td:last-child {
            display: none;
          }
        }
      }
    }
    .btn_bifocal {
      margin-top: 30px;
      a {
        color: #fff;
        font-size: $fs-15;
        display: block;
        background-color: #1c8d44;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
    .btn_detail {
      margin-top: 30px;
      a {
        color: #fff;
        font-size: $fs-15;
        display: block;
        background-color: #ec75a2;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
  .s2 {
    margin-top: 30px;
    padding-bottom: 30px;
    .inner {
      .tit_type {
        display: block;
        padding: 5px;
        width: 100%;
        text-align: center;
        color: #fff;
        background-color: #f1ab3e;
        font-size: 18px;
        margin-bottom: 30px;
      }
      .inline {
        justify-content: space-around;
      }
      .wrap {
        margin-top: 20px;
        h4 {
          width: 88%;
          height: 0;
          text-align: center;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 40px solid #1c95d4;
          color: #fff;
          line-height: 40px;
          margin-left: 20px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          text-align: center;
          td {
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            border: solid 2px #1c95d4;
            text-align: center;
            display: block;
          }
          td:last-child {
            display: none;
          }
        }
      }
      .wrap:nth-of-type(3) {
        h4 {
          border-bottom: 40px solid #87bd43;
        }
        table {
          td {
            border: solid 2px #87bd43;
          }
        }
      }
      .wrap:nth-of-type(4) {
        width: 100%;
        display: inline-block;
        h4 {
          border-bottom: 40px solid #8a2586;
        }
        table {
          td {
            width: 100%;
            border: solid 2px #8a2586;
          }
        }
      }
      .wrap:nth-of-type(5) {
        width: 100%;
        display: inline-block;
        h4 {
          border-bottom: 40px solid #0f6bb0;
        }
        table {
          td {
            width: 100%;
            border: solid 2px #0f6bb0;
          }
        }
      }
      .wrap:nth-of-type(6) {
        h4 {
          border-bottom: 40px solid #ed8f25;
        }
        table {
          td {
            border: solid 2px #ed8f25;
          }
        }
      }
      .wrap:nth-of-type(7) {
        h4 {
          border-bottom: 40px solid #1c8d44;
        }
        table {
          td {
            border: solid 2px #1c8d44;
          }
          td:nth-child(2) {
            display: none;
          }
        }
      }
    }
    .btn_bifocal {
      margin-top: 30px;
      a {
        color: #fff;
        font-size: $fs-15;
        display: block;
        background-color: #1c8d44;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
    .btn_detail {
      margin-top: 30px;
      a {
        color: #fff;
        font-size: $fs-15;
        display: block;
        background-color: #ec75a2;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
  .s3 {
    ul {
      text-align: center;
    }
  }
}
