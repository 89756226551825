/* PC Firsttime */

body.firsttime {
  .sub_menu {
    border-top: 20px #fff solid;
    background: transparent;
    ul {
      li:nth-child(2) {
        background: #3FA9F5;
        a {
          color: #fff;
        }
      }
    }
    .main {
      background: #fff;
    }
  }
  .inline {
    text-align: left;
    a {
      margin: auto 0;
      img {
        margin: 15px 10px 0;
      }
    }
    a:nth-child(-n+3) {
      img {
        margin-top: 0;
      }
    }
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .s1 {
    margin-top: 65px;
    p {
      line-height: 2;
    }
    .inner {
      overflow: hidden;
      p {
        width: 502px;
        float: left;
      }
      img {
        float: right;
      }
    }
  }
  .s2 {
    .inner {
      p {
        line-height: 2;
      }
      .inner_charm {
        position: relative;
        .glasses {
          position: absolute;
          top: 0;
          right: 0;
        }
        ul {
          text-align: left;
          font-size: 0;
          margin: 120px 0 15px 0;
          text-align: center;
          width: 100%;
          li {
            display: inline-block;
            margin: 0 34px 30px 0;
          }
          li:first-child {
            margin-right: 517px;
          }
          li:nth-child(3),
          li:nth-child(5) {
            margin-right: 0;
          }
        }
        .btn_detail {
          a {
            font-size: 1.6em;
            color: #fff;
            display: block;
            background-color: #ec75a2;
            padding: 15px;
            text-decoration: none;
            text-align: center;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .s3 {
    .inner {
      p {
        line-height: 2;
      }
      .inner_type {
        overflow: hidden;
        .inner_txt {
          float: left;
          width: 546px;
        }
        img {
          margin: 10px 0;
          float: right;
        }
      }
      .btn_detail {
        margin-top: 20px;
        a {
          font-size: 1.6em;
          color: #fff;
          display: block;
          background-color: #ed8f25;
          padding: 15px;
          text-decoration: none;
          text-align: center;
          border-radius: 10px;
        }
      }
    }
  }
  .s4 {
    .inner {
      ul {
        text-align: left;
        font-size: 0;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
        li {
          display: inline-block;
          margin: 0 40px 35px 0;
        }
        li:nth-child(even) {
          margin-right: 0;
        }
      }
      .btn_detail {
        a {
          font-size: 1.6em;
          color: #fff;
          display: block;
          background-color: #ec75a2;
          padding: 15px;
          text-decoration: none;
          text-align: center;
          border-radius: 10px;
        }
      }
    }
  }
  .s5 {
    .inner {
      overflow: hidden;
      img {
        float: left;
      }
      p {
        float: right;
        width: 502px;
        line-height: 2;
      }
    }
  }
}
