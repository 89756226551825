/* PC Discount */

body.discount {
  .sub_menu {
    border-top: 20px #fff solid;
    background: transparent;
    .main {
      background: #fff;
    }
  }
  .to_top {
    margin: 30px 0px 45px;
  }
  .inline {
    a {
      display: inline-block;
      margin-right: 32px;
    }
  }
  h2 {
    .small_txt {
      font-size: 18px;
    }
  }
  .s1 {
    margin-top: 65px;
    padding-bottom: 65px;
    h2 {
      .red {
        color: #e7222d;
        font-weight: bold;
      }
    }
    .tit_type {
      display: block;
      padding: 5px;
      width: 100%;
      text-align: center;
      color: #fff;
      background-color: #f1ab3e;
      font-size: 18px;
      margin-bottom: 30px;
    }
    .inline {
      font-size: 0;
      a {
        display: inline-block;
        margin: 15px 30px 0 0;
      }
      a:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    .wrap {
      margin-top: 50px;
      h4 {
        width: 400px;
        height: 0;
        text-align: center;
        font-size: 18px;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 40px solid #1daec1;
        color: #fff;
        line-height: 40px;
        margin-left: 50px;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        td {
          width: 50%;
          box-sizing: border-box;
          padding: 20px;
          border: solid 2px #1daec1;
          text-align: center;
        }
      }
    }
    .btn_detail {
      margin-top: 60px;
      a {
        color: #fff;
        font-size: 1.6em;
        display: block;
        background-color: #ed8f25;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
  .s2 {
    margin-top: 65px;
    padding-bottom: 65px;
    p {
      margin-bottom: 30px;
    }
    .discount_coupon {
      font-size: 0;
      .coupon {
        margin-right: 28px;
        display: inline-block;
        ul {
          li:nth-child(2) {
            text-align: right;
            margin: 10px 0;
          }
        }
      }
      .coupon:nth-of-type(2) {
        margin-right: 0;
      }
    }
  }
}
