/* PC Index */

body.home {
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .mv {
    background: #fff;
    padding-bottom: 60px;
    .bxslider {
      margin-left: 25%;
      margin-right: 25%;
    }
    .bxslider-wrap {
      visibility: hidden;
    }
    .bx-wrapper {
      padding: 42px 0px;
      background: $cl-lightblue;
      box-shadow: none;
      border: 0px;
      margin-bottom: 0px;
      .bx-pager {
        bottom: 18px;
        z-index: 999;
        &.bx-default-pager a {
          background: #fff;
        }
      }
    }
    .bx-wrapper .bx-pager.bx-default-pager a.active,
    .bx-wrapper .bx-pager.bx-default-pager a:focus,
    .bx-wrapper .bx-pager.bx-default-pager a:hover {
      background: $cl-blue;
    }
    .br-blue {
      border: 10px solid $cl-blue;
    }
    .br-orange {
      border: 10px solid #F1A44D;
    }
    .br-drk {
      border: 10px solid #2A4174;
    }
    .br-green {
      border: 10px solid #4CB157;
    }
    .br-pink {
      border: 10px solid $cl-pink;
    }
  }
  .access {
    background: #fff;
    padding-bottom: 20px;
    .left {
      width: 470px;
      float: left;
      img {
        max-width: 100%;
      }
      a {
        width: 230px;
        margin-top: 10px;
        margin-right: 5px;
        display: inline-block;
        &.nm {
          margin-right: 0px;
        }
      }
    }
    .right {
      font-size: $fs-16;
      width: 470px;
      float: right;
      padding-top: 10px;
      p {
        margin-top: 20px;
      }
      .blue {
        color: $cl-bluegreen;
      }
      .tell-small {
        color: $cl-bluegreen;
        display: block;
        text-align: left;
        padding-top: 10px;
        margin-left: -5px;
      }
      table {
        td {
          vertical-align: top;
        }
      }
      .store {
        display: flex;
        flex-wrap: wrap;
        .hldr {
          width: 50%;
          img {
            max-width: 100%;
          }
        }
      }
      h4 {
        text-align: center;
        margin: 30px auto;
      }
    }
  }
}
