/* SP About */

body.about {
  .sub_menu {
    background: transparent;
    .main {
      background: #fff;
    }
  }
  .inline {
    justify-content: space-around;
    text-align: center;
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .s1 {
    margin-top: 65px;
    p {
      line-height: 2.5;
    }
    .tel {
      text-align: center;
      img {
        display: inline-block;
      }
      p {
        font-size: $fs-20;
      }
    }
  }
  .s2 {
    .inner {
      overflow: hidden;
      .inner_cont_left {
        table {
          width: 100%;
          border-collapse: collapse;
          border: solid 1px $cl-grey;
          tr {
            border-bottom: solid 1px $cl-grey;
            td {
              border-right: solid 1px $cl-grey;
              text-align: center;
              padding: 7px;
              position: relative;
              .open_am,
              .open_pm {
                padding-top: 25px;
              }
              .open_am::before {
                position: absolute;
                content: url("/assets/img/calender_am.png");
                top: 10%;
                left: 27%;
                padding-top: 5px;
              }
              .open_pm::before {
                position: absolute;
                content: url("/assets/img/calender_pm.png");
                top: 10%;
                left: 27%;
                padding-top: 5px;
              }
            }
          }
        }
        .btn-store_print {
          display: none;
        }
      }
      .inner_cont_right {
        .open {
          margin-bottom: 30px;
          ul {
            li {
              position: relative;
              margin: 0 30px 0 0;
              p {
                margin-left: 60px;
              }
            }
          }
          .open_am::before {
            content: url("/assets/img/calender_am.png");
            position: absolute;
            top: 10%;
          }
          .open_pm::before {
            content: url("/assets/img/calender_pm.png");
            position: absolute;
            top: 10%;
          }
        }
      }
    }
  }
  .s3 {
    .tel {
      text-align: center;
      img {
        display: inline-block;
      }
    }
    iframe {
      width: 100%;
    }
  }
  .s4 {
    .inner {
      .access_map {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        iframe,
        object,
        embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
