/*
 SP common
---------------------------------------- */

.sp-on {
  display: block;
}

.pc-on {
  display: none;
}

body {
  position: relative;
  background: #fff;
  font-family: $font-family;
  font-size: $font-size-sp;
  line-height: $line-height;
  font-weight: normal;
  color: $color;
  img {
    max-width: 100%;
  }
  .inner {
    margin: 0 auto;
  }
  .inline {
    display: block;
  }
  .clearfix {
    clear: both;
  }
  .title_hldr {
    background: #fff;
    font-size: $fs-10;
    text-align: center;
    border-bottom: 4px solid $cl-blue;
    padding: 2px 10px;
  }
  .side_menu {
    position: fixed;
    width: 80%;
    height: 100vh;
    right: 0px;
    visibility: hidden;
    background: $cl-blue;
    &.active {
      visibility: visible;
    }
    ul {
      li {
        border-bottom: 1px solid $cl-grey;
        a {
          display: block;
          padding: 10px;
          color: #fff;
          font-size: $font-size;
          text-decoration: none;
          &:hover {
            color: $cl-blue;
          }
        }
      }
    }
  }
  .wrapper {
    left: 0;
    transition: all 0.5s ease-in-out;
    &.active {
      -webkit-transform: translateX(-80%);
      transform: translateX(-80%);
      position: relative;
    }
  }
  .logo_hldr {
    background: #fff;
    padding: 8px 10px 10px;
    box-sizing: border-box;
    .l_hldr {
      float: left;
      width: 170px;
      padding-top: 15px;
      img {
        display: block;
        vertical-align: middle;
      }
    }
    .c_hldr {
      float: right;
	  width: 42px;
      padding-top: 9px;
	  margin-right: 15px;
      img {
        display: block;
    	vertical-align: middle;
      }
    }
    .menu_hldr {
      width: 45px;
      float: right;
      .menu-toggle {
        background-color: $cl-blue;
        display: block;
        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 45px;
        font-size: 0;
        text-indent: -9999px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
        border-radius: none;
        border: none;
        cursor: pointer;
        z-index: 9999;
        transition: background 0.3s;
      }
      .menu-toggle:focus {
        outline: none;
      }
      .menu-toggle span {
        display: block;
        position: absolute;
        top: 45%;
        left: 0px;
        right: 0px;
        height: 2px;
        background: #fff;
        width: 30px;
        margin: 0 auto;
      }
      .menu-toggle span::before,
      .menu-toggle span::after {
        position: absolute;
        display: block;
        left: 0px;
        width: 30px;
        height: 2px;
        background-color: #fff;
        content: "";
      }
      .menu-toggle span::before {
        top: -9px;
      }
      .menu-toggle span::after {
        bottom: -9px;
      }
      .menu--htx {
        background-color: $cl-blue;
      }
      .menu--htx span {
        transition: background 0s 0.3s;
      }
      .menu--htx span::before,
      .menu--htx span::after {
        transition-duration: 0.3s, 0.3s;
        transition-delay: 0.3s, 0s;
      }
      .menu--htx span::before {
        transition-property: top, -webkit-transform;
        transition-property: top, transform;
        transition-property: top, transform, -webkit-transform;
      }
      .menu--htx span::after {
        transition-property: bottom, -webkit-transform;
        transition-property: bottom, transform;
        transition-property: bottom, transform, -webkit-transform;
      }
      .menu--htx.is-active span {
        background-color: #fff;
        background: none;
      }
      .menu--htx.is-active span::before {
        top: 0;
        background-color: #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .menu--htx.is-active span::after {
        background-color: #fff;
        bottom: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      .menu--htx.is-active span::before,
      .menu--htx.is-active span::after {
        transition-delay: 0s, 0.3s;
      }
    }
  }
  .breadcrumbs {
    margin-bottom: 30px;
  }
  .banner {
    background: #fff;
  }
  .main_menu {
    background: #fff;
    border-top: 2px solid $cl-blue;
    border-bottom: 2px solid $cl-blue;
    ul {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      li {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        border-right: 1px solid $cl-darkgrey;
        vertical-align: middle;
        p {
          text-align: center;
          display: inline-block;
          color: $cl-pink;
          font-size: $fs-10;
          text-decoration: none;
          padding: 8px;
          font-weight: normal;
          line-height: 1.2em;
          font-feature-settings: "palt";
          letter-spacing: -1px;
        }
        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: 1px solid $cl-darkgrey;
        }
      }
      li:nth-child(even) {
        border-right: 0;
      }
    }
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $cl-bluegreen;
    margin: 1em 0 40px 0;
    padding: 0;
  }
  .contents {
    padding: 0px 10px;
  }
  section {
    background: #fff;
    margin: 0 auto;
    h2 {
      font-size: $fs-16;
      color: $cl-pink;
      text-align: center;
      line-height: 1.2;
      margin-bottom: 30px;
    }
    h3.title {
      display: inline-block;
      width: 300px;
      background-image: url('../img/title-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      font-size: $font-size;
      color: #fff;
      padding-left: 20px;
    }
    .to_top {
      display: inline-block;
      float: right;
    }
    .box {
      padding: 15px 0px;
      width: 100%;
      h4.subtitle {
        display: none;
      }
      ul {
        height: 300px;
        overflow-y: scroll;
        padding: 10px;
        margin-top: 10px;
        li {
          position: relative;
          a {
            text-decoration: none;
            word-break: break-all;
          }
          &:before {
            position: absolute;
            content: "●";
            margin-right: 5px;
          }
          p {
            padding-left: 1.4em;
          }
        }
      }
    }
    .sidebar {
      display: none;
    }
  }
  footer {
    background: #fff;
    margin-bottom: 74px;
    .inline {
      display: flex;
      flex-wrap: wrap;
    }
    .links {
      padding: 20px 10px;
      background: $cl-light-bluegreen;
      .col {
        width: 100%;
        ul {
          li {
            line-height: 38px;
            text-align: left;
            margin-left: 0px;
            list-style: none;
            background: #fff;
            padding: 0 2%;
            margin-bottom: 2px;
            a {
              display: block;
              color: $cl-blue;
              font-size: $fs-12;
              text-decoration: none;
            }
          }
        }
        &:nth-child(4),
        &:nth-child(8) {
          margin-right: 0px;
        }
      }
    }
    .copyright {
      margin: 20px 0px 0px 0px;
      padding: 10px;
      text-align: center;
      background: $cl-blue;
      color: #fff;
      font-size: 10px;
    }
    .fixed_links {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      a {
        background-size: cover;
        background-position: center bottom;
        display: block;
        width: calc(100%/4);
        height: 74px;
        &:nth-child(1) {
          background-image: url('/assets/img/btn-gift-sp.png');
        }
        &:nth-child(2) {
          background-image: url('/assets/img/btn-heart-sp.png');
        }
        &:nth-child(3) {
          background-image: url('/assets/img/btn-trial-sp.png');
        }
        &:nth-child(4) {
          background-image: url('/assets/img/btn-store-sp.png');
        }
      }
    }
    h2 {
      letter-spacing: 10px;
    }
  }
}
