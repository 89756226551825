/* PC Bifocal */

body.bifocal {
  .sub_menu {
    ul {
      li:nth-child(5) {
        background: #3FA9F5;
        a {
          color: #fff;
        }
      }
    }
  }
  .inline-bl {
    justify-content: space-around;
    text-align: center;
  }
  .s1 {
    margin-top: 65px;
    .txtarea {
      p {
        line-height: 2;
        img {
         
          width: 100%;
        }
      }
    }
  }
  .s2 {
    margin-top: 65px;
    img {
      float: right;
      width: 30%;
    }
    .subtitle {
      color: #11B1AD;
      font-weight: bold;
    }
    .subtitle:before {
      content: "■";
    }
    p {
      line-height: 2;
    }
    .wrap {
      padding-top: 30px;
      text-align: center;
      .print-btn {
        width: 100%;
        background-color: #F7931E;
        padding: 10px 0;
        border-radius: 10px;
        text-align: center;
        font-size: 1.14em;
        a {
          color: #fff;
          text-decoration: none;
        }
	  }
      h4 {
        width: 100%;
        text-align: center;
        color: #11B1AD;
        font-size: $fs-16;
        border-top: solid 1px #11B1AD;
        border-bottom: solid 1px #11B1AD;
        padding: 10px 0;
        margin-bottom: 50px;
      }
      img {
        float: none;
        clear: both;
        margin-left: 0;
        width: 100%;
      }
      .notes {
        padding: 10px 0;
        text-align: left;
      }
    }
  }
  .s3 {
    margin-top: 65px;
    .txtarea {
      p {
        line-height: 2;
        img {
          float: none;
          width: 100%;
        }
      }
    }
  }
  .s4 {
    margin-top: 65px;
    .txtarea {
      p {
        line-height: 2;
        img {
          float: none;
          width: 100%;
        }
      }
    }
  }
  .s5 {
    margin-top: 65px;
    padding-bottom: 65px;
    .wrap {
      margin-top: 50px;
      h4 {
        width: 80%;
        height: 0;
        text-align: center;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 40px solid #1c95d4;
        color: #fff;
        line-height: 40px;
        margin-left: 25px;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        td {
          display: block;
          box-sizing: border-box;
          padding: 10px;
          border: solid 2px #1c95d4;
          text-align: center;
        }
      }
      .btn {
        width: 100%;
        background-color: #ec75a1;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        font-size: $fs-15;
        margin-bottom: 50px;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .wrap:nth-of-type(2) {
      h4 {
        border-bottom: 40px solid #87bd43;
      }
      table {
        td {
          border: solid 2px #87bd43;
        }
        tr:nth-last-child(1) {
          td:last-child {
            display: none;
          }
        }
      }
    }
    .wrap:nth-of-type(3) {
      h4 {
        border-bottom: 40px solid #ec75a1;
      }
      table {
        td {
          border: solid 2px #ec75a1;
        }
        td:last-child {
          display: none;
        }
      }
    }
  }
}
