/* PC Trial */

body.trial {
  .sub_menu {
    border-top: 20px #fff solid;
    background: transparent;
    .main {
      background: #fff;
    }
  }
  h2 {
    margin-bottom: 10px;
  }
  .sub_txt {
    text-align: center;
    font-size: 0.9em;
  }
  .inline {
    justify-content: space-around;
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .s1 {
    margin-top: 65px;
    .recommend {
      ul {
        font-size: 0;
        margin-bottom: 60px;
        li {
          display: inline-block;
          margin-right: 19px;
        }
        li:last-child {
          margin-right: -10px;
        }
      }
    }
    p {
      line-height: 2.5;
      margin: 10px 0;
    }
    .btn_detail {
      a {
        font-size: 1.6em;
        color: #fff;
        display: block;
        background-color: #ed8f25;
        padding: 15px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
}
