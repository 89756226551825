/* PC Handling */

body.handling {
  .sub_menu {
    border-top: 20px #fff solid;
    background: transparent;
    ul {
      li:nth-child(4) {
        background: #3FA9F5;
        a {
          color: #fff;
        }
      }
    }
    .main {
      background: #fff;
    }
  }
  .to_top {
    margin: 30px 0px 45px 0px;
  }
  .inline {
    a {
      display: inline-block;
      margin-right: 32px;
    }
  }
  h2 {
    .small_txt {
      font-size: 18px;
    }
  }
  .s1 {
    margin-top: 65px;
    padding-bottom: 65px;
    .inner {
      .tit_type {
        display: block;
        padding: 5px;
        width: 100%;
        text-align: center;
        color: #fff;
        background-color: #f1ab3e;
        font-size: 18px;
        margin-bottom: 30px;
      }
      .inline {
        font-size: 0;
        a {
          display: inline-block;
          margin: 15px 30px 0 0;
        }
        a:nth-of-type(3n) {
          margin-right: 0;
        }
      }
      .wrap {
        margin-top: 50px;
        h4 {
          width: 400px;
          height: 0;
          text-align: center;
          font-size: 18px;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 40px solid #1c95d4;
          color: #fff;
          line-height: 40px;
          margin-left: 50px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          text-align: center;
          td {
            width: 50%;
            box-sizing: border-box;
            padding: 20px;
            border: solid 2px #1c95d4;
            text-align: center;
          }
        }
      }
      .wrap:nth-of-type(2) {
        h4 {
          border-bottom: 40px solid #1c95d4;
        }
        table {
          td {
            border: solid 2px #1c95d4;
          }
        }
      }
      .wrap:nth-of-type(5) {
        h4 {
          border-bottom: 40px solid #87bd43;
        }
        table {
          td {
            width: 100%;
            border: solid 2px #87bd43;
          }
        }
      }
      .wrap:nth-of-type(8) {
        h4 {
          border-bottom: 40px solid #8a2586;
        }
        table {
          td {
            width: 100%;
            border: solid 2px #8a2586;
          }
        }
      }
      .wrap:nth-of-type(11) {
        h4 {
          border-bottom: 40px solid #ed8f25;
        }
        table {
          td {
            border: solid 2px #ed8f25;
          }
        }
      }
      .wrap:nth-of-type(14) {
        h4 {
          border-bottom: 40px solid #1c8d44;
        }
        table {
          td {
            border: solid 2px #1c8d44;
          }
        }
      }
    }
    .btn_bifocal {
      margin-top: 60px;
      a {
        color: #fff;
        font-size: 1.6em;
        display: block;
        background-color: #1c8d44;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
    .btn_detail {
      margin-top: 60px;
      a {
        color: #fff;
        font-size: 1.6em;
        display: block;
        background-color: #ec75a2;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
  .s2 {
    .inner {
      .tit_type {
        display: block;
        padding: 5px;
        width: 100%;
        text-align: center;
        color: #fff;
        background-color: #f1ab3e;
        font-size: 18px;
        margin-bottom: 30px;
      }
      .inline {
        font-size: 0;
        a {
          display: inline-block;
          margin: 15px 30px 0 0;
        }
        a:nth-of-type(3n) {
          margin-right: 0;
        }
      }
      .wrap {
        margin-top: 50px;
        h4 {
          width: 400px;
          height: 0;
          text-align: center;
          font-size: 18px;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 40px solid #d66892;
          color: #fff;
          line-height: 40px;
          margin-left: 50px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          text-align: center;
          td {
            width: 50%;
            box-sizing: border-box;
            padding: 20px;
            border: solid 2px #d66892;
            text-align: center;
          }
        }
      }
      .wrap:nth-of-type(2) {
        h4 {
          border-bottom: 40px solid #d66892;
        }
        table {
          td {
            border: solid 2px #d66892;
          }
        }
      }
      .wrap:nth-of-type(5) {
        h4 {
          border-bottom: 40px solid #1c95d4;
        }
        table {
          td {
            border: solid 2px #1c95d4;
          }
        }
      }
      .wrap:nth-of-type(8) {
        h4 {
          border-bottom: 40px solid #1c8d44;
        }
        table {
          td {
            border: solid 2px #1c8d44;
          }
        }
      }
    }
    .btn_bifocal {
      margin-top: 60px;
      a {
        color: #fff;
        font-size: 1.6em;
        display: block;
        background-color: #1c8d44;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
    .btn_detail {
      margin-top: 60px;
      a {
        color: #fff;
        font-size: 1.6em;
        display: block;
        background-color: #ec75a2;
        padding: 7px;
        text-decoration: none;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
  .s3 {
    margin-top: 65px;
    padding-bottom: 65px;
    .inner {
      ul {
        display: table;
        table-layout: fixed;
        width: 100%;
        text-align: center;
        li {
          display: table-cell;
          vertical-align: middle;
        }
        li:last-child {
          width: 50px;
        }
      }
    }
  }
}
