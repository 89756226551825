@charset "UTF-8";

@import "component/config";
@import "component/mixin";

@import "component/reset";
@import "component/base";

@media (min-width: 768px) {
	@import "parts/pc/pc-common";
	@import "parts/pc/pc-index";
	@import "parts/pc/pc-contact";
	@import "parts/pc/pc-bifocal";
	@import "parts/pc/pc-trial";
	@import "parts/pc/pc-about";
	@import "parts/pc/pc-firsttime";
	@import "parts/pc/pc-recommend";
	@import "parts/pc/pc-handling";
	@import "parts/pc/pc-topics";
	@import "parts/pc/pc-discount";
}

@media (max-width: 767px) {
	@import "parts/sp/sp-common";
	@import "parts/sp/sp-index";
	@import "parts/sp/sp-contact";
	@import "parts/sp/sp-bifocal";
	@import "parts/sp/sp-trial";
	@import "parts/sp/sp-about";
	@import "parts/sp/sp-firsttime";
	@import "parts/sp/sp-recommend";
	@import "parts/sp/sp-handling";
	@import "parts/sp/sp-topics";
	@import "parts/sp/sp-discount";
}