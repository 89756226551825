/* SP Contact */

body.contact{
	& .sub_menu{
		background: transparent;
		& .main{
			background: #fff;
		}
	}
	& .inline{
		justify-content: space-around;
	}
	& .to_top{
		margin: 30px 0px 45px 0px;
	}
	& .s1{
		margin-top: 65px;
		& span.day{
			background: $cl-bluegreen;
			color: #fff;
			padding: 5px;
		}
		& p{
			line-height: 2.5;
		}
		& .tel{
			text-align: center;
			& img{
				display: inline-block;
			}
			& p{
				font-size: $fs-20;
			}
		}
	}
	& .s2{
		& table{
			width: 100%;
			& td{
				padding-bottom: 20px;
			}
			& td:first-child{
				width: 20%;
			}
		}
		& .red{
			color: #FF0000;
		}
		& form{
			margin-top: 47px;
		}
		& input{
		    width: 100%;
		    height: 40px;
		    border: 1px solid #f0f0f0;
		    padding: 10px;
		    background: $cl-lightgrey;
		}
		& input[type="submit"]{
			display: inline-block;
			width: auto;
			border-radius: 5px;
			height: 30px;
			padding: 0px 20px;
			background: #f5f6f6; /* Old browsers */
			background: -moz-linear-gradient(top, #f5f6f6 0%, #dbdce2 21%, #b8bac6 100%, #f5f6f6 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #f5f6f6 0%,#dbdce2 21%,#b8bac6 100%,#f5f6f6 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #f5f6f6 0%,#dbdce2 21%,#b8bac6 100%,#f5f6f6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f6f6', endColorstr='#f5f6f6',GradientType=0 ); /* IE6-9 */
		}
		& .submit-hldr{
			text-align: center;
		}
	}
	& .s3{
		padding-bottom: 94px;
	}
}