/* PC discount */

body.discount{
	& .sub_menu{
		background: transparent;
		& .main{
			background: #fff;
		}
	}
	& .to_top{
		margin: 30px 0px 45px 0px;
	}
	& .inline{
		& a{
			display: inline-block;
		}
	}
	& h2{
		& .small_txt{
			font-size: 18px;
		}
	}
	& .s1{
		margin-top: 30px;
		padding-bottom: 30px;
		& h2{
			& .red{
				color: #e7222d;
				font-weight: bold;
			}
		}
		& .tit_type{
			display: block;
			padding: 5px;
			width: 100%;
			text-align: center;
			color:#fff;
			background-color: #f1ab3e;
			font-size: 18px;
			margin-bottom: 30px;
		}
		& .inline{
			font-size: 0;
			& a{
				display: inline-block;
				margin: 15px 30px 0 0;
			}
			& a:nth-of-type(3n){
				margin-right: 0;
			}
		}
		& .wrap{
			margin-top: 30px;
			h4{
				width: 80%;
				height: 0;
				text-align: center;
				font-size: 18px;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-bottom: 40px solid #1daec1;
				color: #fff;
				line-height: 40px;
				margin-left: 20px;
			}
			& table{
				width: 100%;
				border-collapse: collapse;
				text-align: center;
				border-bottom: solid 2px #1daec1;
				& td{
					width: 100%;
					box-sizing: border-box;
					padding: 10px;
					border:solid 2px #1daec1;
					text-align: center;
					display: block;
					border-bottom: none;
				}
			}
		}
		& .btn_detail{
			margin-top: 30px;
			a{
				color:#fff;
				font-size: $fs-15;
				display: block;
				background-color: #ed8f25;
				padding: 7px;
				text-decoration: none;
				text-align: center;
				border-radius: 10px;
			}
		}
	}
	& .s2{
		margin-top: 30px;
		padding-bottom: 30px;
		& p{
			margin-bottom: 30px;
		}
		& .discount_coupon{
			font-size: 0;
			& .coupon{
				& ul{
					& li{
						margin-bottom: 20px;
					}
					& li:nth-child(2){
						display: none;
					}
				}
			}
		}
	}
}